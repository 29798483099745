body {
  background-color: #333333;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.approval-table-header:first-of-type {
  border-top-left-radius: 0.375rem;
}

.approval-table-header:last-child {
  border-top-right-radius: 0.375rem;
}

.background-black {
  background-image: url('https://cdn.badgerprints.com/Tiles/Black%20Tile.svg');
  background-size: 200px;
  background-repeat: repeat;
}

.background-blue {
  background-image: url('https://cdn.badgerprints.com/Tiles/Blue%20Tile.svg');
  background-size: 200px;
  background-repeat: repeat;
}

@font-face {
  font-family: 'NeueHaasBlack';
  src: url('https://cdn.badgerprints.com/font/NeueHaasDisplayBlack.ttf');
}

@font-face {
  font-family: 'NeueHaas';
  src: url('https://cdn.badgerprints.com/font/NeueHaasDisplayMediu.ttf');
}

.neueblack{
  font-family: 'NeueHaasBlack';
}

.neue {
  font-family: 'NeueHaas';
}

.blue-text {
  color: #0080ff;
}
